<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>工厂资质代传</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="95px" class="login-form">
            <div class="block">
                <div class="header">服务能力</div>
                <div class="list">
                    <div class="flex" style="margin:10px 0 20px 0;">
                        <div>加工属性：</div>
                        <div class="select_list flex">
                            <el-checkbox-group v-model="selectValue" size="small">
                                <el-checkbox style="margin:5px 10px 5px 0;" v-for="(item,idx) in selectList" :key="idx" :label="item.id" border>
                                    {{item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="flex" style="margin:10px 0 20px 0;">
                        <div>主要木材加工种类：</div>
                        <div class="select_list flex">
                            <el-checkbox-group v-model="selectValue" size="small">
                                <el-checkbox style="margin:5px 10px 5px 0;" v-for="(item,idx) in selectList" :key="idx" :label="item.id" border>
                                    {{item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="flex" style="margin:10px 0 20px 0;">
                        <div>主打产品：</div>
                        <div class="select_list flex">
                            <el-checkbox-group v-model="selectValue" size="small">
                                <el-checkbox style="margin:5px 10px 5px 0;" v-for="(item,idx) in selectList" :key="idx" :label="item.id" border>
                                    {{item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="header">工厂现状</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item prop="personNum" label="总人数：">
                        <el-input type="number" style="width:200px;" v-model="ruleForm.personNum" placeholder="请输入数量"></el-input>
                    </el-form-item>
                    <el-form-item prop="time" label="成立日期：">
                        <el-date-picker v-model="ruleForm.time" type="date"
                            placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="工厂图片："  label-width="95px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.design_photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.design_photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.design_photos.length || 0}}/5</div>
                    </el-form-item>
                    <el-form-item label="工厂视频：" label-width="95px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.photos && item.photos.length>0" v-model="item.photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.photos.length || 0}}/5</div>
                    </el-form-item>
                    <el-form-item prop="desc" label="工厂简介：">
                        <el-input type="textarea" :rows="4" v-model="ruleForm.desc" style="width:80%" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">相关资料</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="获奖荣誉证书："  label-width="125px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.design_photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.design_photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.design_photos.length || 0}}/5</div>
                    </el-form-item>
                    <el-form-item label="企业质量体系证：" label-width="125px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.photos && item.photos.length>0" v-model="item.photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.photos.length || 0}}/5</div>
                    </el-form-item>
                    <el-form-item prop="" label="官网链接：">
                        <el-input style="width:300px;" v-model="ruleForm.link" placeholder="请输入官网链接"></el-input>
                    </el-form-item>
                    <div class="flex">
                        <div style="width:95px;text-align:right;padding-right:10px;">工艺能力：</div>
                        <div style="width:calc(100% - 95px);border:1px solid #EBEEF5;">
                            <el-table :data="ruleForm.ability" style="width: 100%">
                                <el-table-column prop="name" label="工艺名称">
                                </el-table-column>
                                <el-table-column prop="zhibiao" label="衡量指标">
                                </el-table-column>
                                <el-table-column prop="privs" label="对应值">
                                    <template slot-scope="scope">
                                        <div>{{scope.row.bfb}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="address" label="主要工艺">
                                    <template slot-scope="scope">
                                        <div>{{scope.row.yes}}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <el-form-item prop="" label="审批：">
                        <el-radio-group v-model="ruleForm.radio">
                            <el-radio :label="0">通过</el-radio>
                            <el-radio :label="1">驳回</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div style="padding:20px 0 20px 95px;">
                        <el-button size="small" type="primary" style="width:80px;" @click="submitForm()">保存</el-button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import localForage from 'localforage';
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { getRoles,delRol } from '@/service/purview';
    import UploadImgs from '@/components/UploadImgs.vue';
    export default {
        components: {
            UploadImgs
        },
        data() {
            return {
                rules: {
                    personNum: [
                        { required: true, message: '请输入申请数量', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输入申请说明', trigger: 'blur' }
                    ]
                },
                ruleForm:{
                    title:"xxxxx家具品牌公司",
                    person:"若风",
                    personNum:"",
                    stat:0,
                    time:"",
                    desc:"",
                    design_photos:[],
                    photos:[],
                    link:"",
                    ability:[],
                    radio:"",
                },
                selectList:[
                    {id:0,name:"充值点数",},
                    {id:1,name:"需求发布次数",},
                    {id:2,name:"广播消息次数",},
                    {id:3,name:"查看次数",},
                ],
                selectIndex:0,
                selectValue:[],
                showImgs:false,
            }
        },
        created() {
            this.showImgs = true;
            var obj = {
                name:"喷漆",
                zhibiao:"执色、黏合度",
                bfb:"90%",
                yes:"是"
            }
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.back();
                this.$message.success("保存成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 40px;
    }
    .list>div>div:first-child {
        width: 126px;
        text-align: right;
        color: #000;
        margin-right: 10px;
    }
    div>>> .el-checkbox__inner {
        display: none;
    }
    /* .select_list>div {
        padding:0 15px;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        border: 1px solid #606266;
        border-radius: 5px;
        margin-top: 3px;
        margin-right:15px;
    }
    .select_list>div.active {
        border: 1px solid rgb(64, 158, 255);
        color: rgb(64, 158, 255);
    } */
</style>